/* eslint-disable */
import {request} from '../network';
import store from '@/store';


// 添加地址
export function setAddress(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/download/setAddress ',
    data
  })
}
// 获取地址
export function getAddress(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/download/getAddress ',
     data
  })
}
