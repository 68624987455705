<template>
  <div class="referInfo">
    <el-form
      ref="referRef"
      :model="referForm"
      label-position="top"
      class="checkForm"
      :rules="referInfoRules"
    >
      <!-- 个人信息 -->
      <div class="cont_title">个人信息</div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            <el-input
              ref="name"
              v-model="referForm.name"
              placeholder="填写真实姓名"
              size="small"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="gender">
            <el-radio-group
              v-model="referForm.gender"
              ref="gender"
              size="small"
            >
              <el-radio :label="0">男</el-radio>
              <el-radio :label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="id_card">
            <el-input
              ref="id_card"
              v-model="referForm.id_card"
              placeholder="填写真实身份证号"
              size="small"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出生日期" prop="birthday">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-select v-model="bdYear" placeholder="年">
                  <el-option
                    v-for="(item, index) in yearArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="bdMonth" placeholder="月">
                  <el-option
                    v-for="(item, index) in monthArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select
                  ref="birthday"
                  v-model="bdDay"
                  placeholder="日"
                  @change="getTime"
                >
                  <el-option
                    v-for="(item, index) in dayArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="籍贯" prop="jiguan_code">
            <el-row :gutter="20">
              <el-col :span="12">
                <!-- @keyup.enter.native="selsectProvince" -->
                <el-select
                  v-model="jgProvinceCode"
                  placeholder="省"
                  @change="changeProvince(1, $event)"
                >
                  <el-option
                    v-for="item in selectProvince"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select
                  ref="jiguan_code"
                  v-model="jiguan_city"
                  placeholder="市"
                  @change="getJiguan()"
                >
                  <el-option
                    v-for="item in selectCityJiguan"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="民族" prop="nation">
            <el-input
              ref="nation"
              v-model="referForm.nation"
              placeholder="填写民族"
              size="small"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入学年份" prop="start_school_date">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-select
                  v-model="start_grade"
                  placeholder="年级"
                  clearable
                  @change="getyear()"
                >
                  <el-option
                    v-for="(item, index) in gradeArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select
                  ref="start_school_date"
                  v-model="start_year"
                  placeholder="年份"
                  clearable
                  @change="getyear()"
                >
                  <el-option
                    v-for="(item, index) in yearArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="政治面貌" prop="political_status">
            <el-select
              ref="political_status"
              v-model="referForm.political_status"
              placeholder="填写政治面貌"
              clearable
            >
              <el-option
                v-for="item in political_status"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号">
            <el-input
              v-model="referForm.phone"
              placeholder="请输入手机号"
              size="small"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" prop="email">
            <el-input
              v-model="referForm.email"
              placeholder="填写电子邮箱"
              size="small"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 收件信息 （不可编辑） -->
      <div class="cont_title">收件信息</div>
      <el-form :model="addressForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="收件人" prop="certificate_name">
              <el-input
                v-model="addressForm.certificate_name"
                placeholder="填写真实姓名"
                size="small"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收件人电话" prop="certificate_phone">
              <el-input
                v-model="addressForm.certificate_phone"
                placeholder="填写真实电话"
                size="small"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="收货地址" prop="certificate_province">
              <el-select
                ref="certificate_province"
                v-model="addressForm.certificate_province"
                :disabled="true"
                placeholder="选择省份"
                @change="changeProvince(3, $event)"
              >
                <el-option
                  v-for="item in selectProvince"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="no_label" label=" " prop="certificate_city">
              <el-select
                ref="certificate_city"
                v-model="addressForm.certificate_city"
                :disabled="true"
                placeholder="选择城市"
              >
                <el-option
                  v-for="item in selectCitySchool"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="no_label" label=" " prop="county">
              <!-- <el-select
                ref="school"
                v-model="addressForm.school"
                clearable
                placeholder="选择所在区县"
              >
                <el-option
                  v-for="item in selectSchoolList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
              <el-input
                v-model="county"
                placeholder="选择所在区县"
                size="small"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="" prop="certificate_address">
          <el-input
            ref="certificate_address"
            v-model="certificate_address"
            placeholder="填写详细地址"
            size="small"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form-item class="btn">
        <el-button @click="confirm">提交资料</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/* eslint-disable */
import { getProAndCity, getSchool } from "r/index/signUp";
import { getAccountInfo, setAccountInfo } from "r/index/info";
import { getAddress } from "r/index/certificate";
import validator from "common/validator";

export default {
  name: "CheckForm",

  data() {
    const validateContent = (rule, value, callback) => {
      if (!this.referForm.birthday) {
        callback(new Error("出生日期不能为空！"));
      } else {
        callback();
      }
    };
    const validateContents = (rule, value, callback) => {
      if (!this.referForm.jiguan_code) {
        callback(new Error("籍贯不能为空！"));
      } else {
        callback();
      }
    };
    return {
      isUploading: false, // 图片上传蒙层
      referForm: {
        name: null,
        gender: null,
        id_card: null,
        birthday: null,
        jiguan_code: null,
        nation: null,
        start_school_date: null,
        political_status: null,
        phone: null,
        email: null,
        school_province: null,
        school_city: null,
        school: null,
        school_contacts: null,
        school_phone: null,
      },

      schoolName: {},
      referInfoRules: {
        name: [{ required: true, message: "姓名不能为空！", trigger: "blur" }],
        gender: [
          { required: true, message: "性别不能为空！", trigger: "change" },
        ],
        id_card: [
          { required: true, message: "身份证号不能为空！", trigger: "blur" },
          { validator: validator.validateid_card, trigger: "blur" },
        ],
        birthday: [
          {
            required: true,
            validator: validateContent,
            trigger: "change",
          },
        ],
        jiguan_code: [
          { required: true, validator: validateContents, trigger: "change" },
        ],
        nation: [
          { required: true, message: "民族不能为空！", trigger: "change" },
        ],
        start_school_date: [
          {
            required: true,
            message: "入学年份不能为空！",
            trigger: "change",
          },
        ],
        political_status: [
          { required: true, message: "政治面貌不能为空！", trigger: "blur" },
        ],
        // email: [
        //   { required: true, message: "电子邮箱不能为空！", trigger: "blur" },
        //   { validator: validator.validateEmail, trigger: "blur" },
        // ],
        school_province: [
          {
            required: true,
            message: "请选择所在学校省份！",
            trigger: "change",
          },
        ],
        school_city: [
          {
            required: true,
            message: "请选择所在学校城市！",
            trigger: "change",
          },
        ],
        school: [
          { required: true, message: "请选择所在学校！", trigger: "change" },
        ],
        school_contacts: [
          { required: true, message: "请填写中学联系人！", trigger: "blur" },
        ],
        school_phone: [
          {
            required: true,
            message: "中学联系人电话（手机）不能为空！",
            trigger: "blur",
          },
          { validator: validator.validatePhone, trigger: "blur" },
        ],
        // backgroundPicture: [{ required: true, message: "请上传图片" }],
      },
      selectProvince: [], //省份
      CityName: {}, //根据省份筛选出所有城市的对象
      selectCityJiguan: [], //籍贯城市列表
      selectCitySchool: [], //就读中学-城市列表
      selectSchoolList: [], //就读中学-中学列表
      jgProvinceCode: "", //省份id
      jiguan_city: "", //城市id(和省份id拼接)
      yearArrs: [], //生日涉及的近10年的年份
      monthArrs: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      dayArrs: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      bdYear: "", //选择的生日年份
      bdMonth: "", //选择的生日月份
      bdDay: "", //选择的生日日期
      //入学年份（初中高中）
      gradeArrs: ["初中", "高中", "大学"],
      //选择年级
      start_grade: "",
      start_year: "",
      // 政治面貌
      political_status: [
        {
          id: 1,
          name: "党员",
        },
        {
          id: 2,
          name: "团员",
        },
        {
          id: 3,
          name: "群众",
        },
      ],
      // 上传的图片
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      resultPicture: "",
      dialogVisible: false,
      disabled: false, //限制输入框
      // 收货信息模块
      // 收件地址-地址栏
      addressForm: {
        certificate_name: "",
        certificate_phone: "",
        certificate_province: null,
        certificate_city: null,
        certificate_address: "",
      },
      county: "", //区县
      certificate_address: "", //详细地址
    };
  },
  mounted() {},
  created() {
    const code = this.$store.state.indexIdentity;
    this.referForm.phone = window.localStorage.getItem("index-phone-all"); //本地取用户手机号
    this.referForm.email = window.localStorage.getItem("index-email-all"); //本地取用户邮箱
    console.log(this.referForm.email, " this.referForm.email");
    let signUpFile = window.localStorage.getItem(
      "sign_up_file_" + window.localStorage.getItem("index-phone-all") + code
    );
    // console.log(signUpFile);
    if (signUpFile && JSON.parse(signUpFile).length > 0) {
      this.fileList = JSON.parse(signUpFile);
    }
    this.getSchool();
    this.getProAndCity();
    this.getTenYear();
    this.getAccount(); // 页面信息个人获取
    this.getAddress(); //收件信息获取
  },
  watch: {
    referForm(val) {
      console.log(val, "val");
      for (var k in val) {
        if (val[k] == null || !val[k]) {
          val[k] = this.referForm[k];
        }
      }
      this.selfInfo = val;
      if (val.gender && val.gender !== "") {
        // console.log(val.gender)
        this.referForm.gender = val.gender;
      }
      //出生日期
      if (val.birthday && val.birthday !== "") {
        this.bdYear = val.birthday.slice(0, 4); //前4位取年
        this.bdMonth = val.birthday.slice(4, 6); //中2位取月
        this.bdDay = val.birthday.slice(6, 8); //后2位取日
      }
      //入学年份
      if (val.start_school_date && val.start_school_date !== "") {
        this.start_year = val.start_school_date.slice(0, 4); //年份
        this.start_grade = val.start_school_date.slice(5, 8); //年级
      }
      //籍贯
      if (val.jiguan_city !== null && val.jiguan_province !== null) {
        if (this.referForm.jiguan_city) {
        }
        this.jgProvinceCode = Number(val.jiguan_province);
        this.changeProvince(1, this.jgProvinceCode, true);
        this.jiguan_city = Number(val.jiguan_city);
      }
      // console.log(val.school_province, val.school_city, val.school);
      //学校
      if (
        val.school_province !== null &&
        val.school_city !== null &&
        val.school !== null
      ) {
        this.referForm.school_province = Number(val.school_province);
        this.referForm.school_city = Number(val.school_city);
        this.referForm.school = Number(val.school);
        // console.log(
        //   this.referForm.school_province,
        //   this.referForm.school_city,
        //   this.referForm.school
        // );
        this.changeProvince(2, this.referForm.school_province, true);
        // console.log(this.referForm.school_city, 12322);
        this.changeCity(this.referForm.school_city, true);
      }
    },
  },
  methods: {
    // 收件信息获取
    getAddress() {
      // console.log(111111)
      getAddress({}).then((res) => {
        // console.log(res, "getAddress");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.addressForm.certificate_name = res.data.info.certificate_name;
        this.addressForm.certificate_phone = res.data.info.certificate_phone;
        this.addressForm.certificate_province =
          res.data.info.certificate_province;
        this.addressForm.certificate_city = res.data.info.certificate_city;
        this.selectCitySchool = this.getCurrentCityLists(
          this.addressForm.certificate_province
        );
        if (res.data.info.certificate_address) {
          this.county = res.data.info.certificate_address.split("-")[0];
          this.certificate_address =
            res.data.info.certificate_address.split("-")[1];
          // console.log(res.data.info.certificate_address.split("-"))
        }
      });
    },
    //获取近10年的年份
    getTenYear() {
      let nowDate = new Date();
      let currentYear = nowDate.getFullYear();
      // console.log(nowDate, currentYear);
      let yearArr = [];
      let rxYearArr = [];
      for (let i = 0; i < 42; i++) {
        let year = currentYear - i;
        yearArr.push(year);
      }
      // console.log(yearArr, "yearArr");
      for (let i = 0; i < 10; i++) {
        let year = currentYear - i;
        rxYearArr.push(year);
      }
      this.yearArrs = yearArr;
      this.rxYearArrs = rxYearArr;
    },
    // 页面信息个人获取
    getAccount() {
      getAccountInfo({}).then((res) => {
        console.log(res, "getAccountInfo");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.referForm = res.data.info;
        if (res.data.info.jiguan_province && res.data.info.jiguan_city) {
          this.referForm.jiguan_code =
            res.data.info.jiguan_province.toString() +
            res.data.info.jiguan_city.toString();
        }
        if (res.data.info.email == null) {
          this.referForm.email = window.localStorage.getItem("index-email-all"); //本地取用户邮箱
        }
      });
    },
    //组装出生日期
    getTime() {
      this.referForm.birthday =
        this.bdYear.toString() +
        this.bdMonth.toString() +
        this.bdDay.toString();
      // console.log(this.referForm.birthday);
    },
    //组装入学年份
    getyear() {
      this.referForm.start_school_date =
        this.start_year.toString() + "-" + this.start_grade.toString();

      console.log(this.referForm.start_school_date);
    },
    // 获取省份城市
    getProAndCity() {
      getProAndCity({}).then((res) => {
        // console.log(res, "getProAndCity");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.selectProvince = res.data.province_list;
        // this.CityName = res.data.city_list;
        // console.log(this.selectProvince, this.CityName);
      });
    },

    //选择籍贯省份change事件
    changeProvince(type, val, status) {
      // console.log(type, val);
      if (type == 1) {
        this.selectCityJiguan = [];
        this.selectCityJiguan = this.getCurrentCityLists(val);
        if (!status) {
          this.jiguan_city = "";
          this.referForm.jiguan_code = "";
        }
      } else {
        this.selectCitySchool = [];
        this.selectCitySchool = this.getCurrentCityLists(val); //在此调用城市筛选列表
        if (!status) {
          this.referForm.school_city = "";
          this.referForm.school = "";
          this.selectSchoolList = []; //重新选择省份时候，学校list清空
        }
      }
    },

    //返回当前省份下的城市列表
    getCurrentCityLists(val) {
      let currentCity = [];
      if (
        this.CityName == null ||
        !this.CityName ||
        Object.values(this.CityName).length == 0
      ) {
        getProAndCity({})
          .then((res) => {
            // console.log(res, "getProAndCity");
            if (res.data.code === 200) {
              // this.selectProvince = res.data.province_list;
              this.CityName = res.data.city_list;

              for (let key in this.CityName) {
                if (this.CityName[key].parent_id == val) {
                  currentCity.push(this.CityName[key]);
                }
              }
              return currentCity;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        for (let key in this.CityName) {
          if (this.CityName[key].parent_id == val) {
            currentCity.push(this.CityName[key]);
          }
        }
        return currentCity;
      }
      return currentCity;
    },
    // 籍贯城市change事件
    getJiguan() {
      this.referForm.jiguan_code =
        this.jgProvinceCode.toString() + this.jiguan_city.toString();
    },
    getSchool() {
      getSchool({}).then((res) => {
        // console.log(res, "getSchool");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.schoolName = res.data.school_list;
      });
    },
    // 学校城市change事件
    changeCity(val, status) {
      // console.log(12345333,val,status)
      this.selectSchoolList = [];
      this.selectSchoolList = this.getCurrentSchoolLists(val);
      if (!status) {
        this.referForm.school = "";
      }
    },
    // 返回当前城市下的学校列表
    getCurrentSchoolLists(val) {
      let currentSchool = [];
      // console.log(this.schoolName);
      if (
        this.schoolName == null ||
        !this.schoolName ||
        Object.values(this.schoolName).length == 0
      ) {
        getSchool({})
          .then((res) => {
            // console.log(res)
            if (res.data.code === 200) {
              this.schoolName = res.data.school_list;
              let code = this.$store.state.indexIdentity;
              window.localStorage.setItem("schoolName" + code,
                JSON.stringify(res.data.list)
              );
              for (let key in this.schoolName) {
                if (this.schoolName[key].city_id == val) {
                  currentSchool.push(this.schoolName[key]);
                }
              }
              return currentSchool;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        for (let key in this.schoolName) {
          if (this.schoolName[key].city_id == val) {
            currentSchool.push(this.schoolName[key]);
          }
        }
        return currentSchool;
      }
      return currentSchool;
    },

    // 滚动到固定地方
    scrollView(object) {
      // console.log(object, "object");
      for (let i in object) {
        if (!i) {
          continue;
        }
        let dom = this.$refs[i];
        // console.log(i, dom);
        if (!dom) {
          continue;
        }
        // 这里是针对遍历的情况（多个输入框），取值为数组
        if (Object.prototype.toString.call(dom) !== "[object Object]") {
          dom = dom[0];
        }
        // 第一种方法（包含动画效果）
        dom.$el.scrollIntoView({
          // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: "center",
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: "smooth",
        });
        break; // 因为我们只需要检测一项,所以就可以跳出循环了
      }
    },
    confirm() {
      // console.log(111);
      // console.log(this.referForm.birthday);
      this.$refs["referRef"].validate((valid, object) => {
        if (!valid) {
          this.scrollView(object);
          return false;
        }
        setAccountInfo(this.referForm).then((res) => {
          if (res.data.code != 200) {
            if (res.data.code == 400068) {
              this.$parent.statuss();
              return this.$message.error(res.data.msg);
            }
            return this.$message.error(res.data.msg);
          }
          this.$emit("getstatus", res.data.status);
          this.$message.success(res.data.msg);
          this.referForm = res.data.data;
          //  this.getAccount(); // 页面信息个人获取（刷新）
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/btn";
@import "a/scss/SignUp/referInfo";
</style>
