/* eslint-disable */
import {request} from '../network';
import store from '@/store';


// 获取资料（个人信息）
export function getAccountInfo(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/getAccountInfo',
    data
  })
}

// 保存个人资料
export function setAccountInfo(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/setAccountInfo',
    data
  })
}
